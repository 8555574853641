
import React from "react";

//import Layout from "../components/Layout";
import { Link } from 'react-router-dom';

import NotFoundImage from "../assets/img/illustrations/404.svg";


export const NotFound = () => {
  return (
          <div className="row fadein">
            <div className="col-xs-12 text-center mx-auto fadein">
            <h1 className="text-primary mt-5">
                  Page not <span className="fw-bolder">found</span>
                </h1>
                <p className="lead my-4 text-center">
                  Oops! Looks like you followed a bad link. If you think this is a
                  problem with us, please tell us. <br/>
                  <Link to="/"  className="btn btn-outline-primary" >
                <i className="bi bi-chevron-double-right mt-2"></i>
                  Go back home
                </Link>
            </p>
                <Link to="/" className="card-link">
                  <img src={NotFoundImage} className="img-fluid w-75" alt="hiii" />
                </Link>
                
               
                

            </div>
          </div>
  );
};
