import { useState, useEffect } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { DynamicSearch, Paginator, Loading } from "../../components";
import { isIn, DynamicLinks, ToHTML } from "../../components/helpers";
import { IRenderList } from "../../types/models";
import { useModelLayoutContext } from "./default_model_layout";
import { renderTemplate } from "./use_renders";



export function RenderList() {

    const { cnx, viewData } = useModelLayoutContext()!
    const location = useLocation();
    const {search } = location;
  
    //const locState = location.state;
    const [searchQuery] =useSearchParams();
  
    const navigate = useNavigate();
  
    const [state, setState] = useState<IRenderList>({
      dataList: null,
      htmlString: null,
      currentPage: 1,
      currentUrl: "/",
      modelState: "idle" // "idle" | "loading" | "loaded" | "error"
    });
  
    const { routeName, searchKey, name, listTemplate } = viewData;
    let limit = Number(searchQuery.get('limit') ?? 5);
    let currentPage = Number(searchQuery.get('page') ?? state.currentPage);
   
    let currentUrl = routeName + (search ?? `?limit=${limit}&page=${currentPage}`);
  
    useEffect(() => {
      const setData = (data: any)=> {
      let _dd = { ...state, ...data };
      setState(_dd);
      cnx.appCaches.set(currentUrl, _dd)
    };  
  
      if (state.currentUrl !== currentUrl || state.modelState === 'idle') {
        let sts = cnx.appCaches.get(currentUrl);
        if (sts && sts !== state) {
          setState(sts)
        } else if( !isIn("loading error",state.modelState)) {
  
          setData({ modelState: "loading" })
          cnx.ajax.get(currentUrl).then(({ error, data}) => {
  
              if (data) {
                let htmlString = renderTemplate(viewData.listTemplate,data);
  
                let _data: any = {
                  currentPage,
                  dataList: data,
                  htmlString,
                  currentUrl,
                  modelState: "loaded"
                };
  
                setData(_data)
              } else {
                setData({ modelState: "error" })
                console.log(error)
                navigate('/notfound');
              }
            }
            ).catch((error) => {
              setData({ modelState: "error" })
              console.error(error)
              navigate('/error', {state:{error}});
  
              });
            }
        
      }
  
    }, [routeName, currentPage, currentUrl, cnx, listTemplate, navigate, search, state])
  
    if(state.modelState === "loaded" && state.dataList?.length){
    return <> <DynamicLinks  {...{routeName,appCaches:cnx.appCaches,cacheKey: currentUrl,navigate, dataList:true}}>
        {searchKey ? <DynamicSearch  viewData={viewData} /> : ""}
  
        {state.htmlString?.length ? state.htmlString.map((html: any, i) => <ToHTML key={i} __html={html} />) : <h2 className='text-center my-5'>NO {name.toUpperCase()} FOUND</h2>}
      </DynamicLinks >
      <Paginator  ajax={cnx.ajax} viewData={viewData} currentPage={currentPage} pagesPerPage={limit} />
      </>
    }
  
    return< Loading />
  
  }