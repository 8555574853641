
import { GoHome } from './gohome';


export const RequireAdmin = () => {
  return (
          <div className="row fadein">
            <div className="col-xs-12 text-center mx-auto fadein">
            <h1 className="text-danger mt-5">
                  Not <span className="fw-bolder">Authorized</span>
                </h1>
                <p className="lead my-4 text-center text-danger">
                  Oops! Looks like you require access with admin privileges . <br/>
            </p>
            <GoHome/>
            </div>
          </div>
  );
};
