import { IModelForm } from '../../types/utils';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useModelLayoutContext } from './default_model_layout';
import { AppForms } from '../../utils/forms';
import { Loading } from '../../components';

export function EditModel() {

  const { cnx, viewData } = useModelLayoutContext()!
  var modelForm: IModelForm = useLoaderData() as any;
  // const { ajax } = cnx;
  const { routeName, name } = viewData
  const location = useLocation();
  const navigate = useNavigate();

  const paramValue = useParams()[name + 'Id']

  const routeParamUrl = routeName + '/' + paramValue;

  const [dataState, setDataState] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    // require admin 
    if (!cnx.isAdmin)
      navigate('/unauthorized');
  }, [cnx.isAdmin, navigate]);

  // check if post in location and set state
  useEffect(() => {
    // console.log('edit data loc state: ',location.state)
    if (!dataState && location.state?.model) {
      modelForm.initialState = location.state?.model;
      setDataState(location.state?.model)
    } else if (paramValue && !dataState) {
      cnx.ajax.get(routeParamUrl).then((res) => {
        let { error, data } = res;
        if (data) {
          setDataState(data);
          modelForm.initialState = data
        } else if (error) {
          navigate('error', { state: error })
        }
      })

    }
  }, [cnx.ajax, dataState, location.state, modelForm, navigate, paramValue, routeName, routeParamUrl])

  return dataState ? (

    <div className=" container-fluid p-0 justify-content-md-center fadein">
      <div className='row'>
        <div className="col-md-10 col-sm-12 mx-auto ">
          <h4 className='text-center'>{'Update ' + name}</h4>
          <hr className='bg-warning' />
          {
            // key is very important it makes form rerender on props change
            <AppForms key={modelForm.name} modelForm={modelForm} method='put' action={routeParamUrl} />

          }
        </div>
      </div>
    </div>
  ) : <Loading />;

}
