
import React from "react";
import {useLocation} from 'react-router-dom';
//import Layout from "../components/Layout";
import { Link } from 'react-router-dom';
import MaroonCard from "../components/MaroonCard";


export const Thanks = () => {
  const location = useLocation();
  return (
    <MaroonCard id="thanks" size='xl' classNames="bg-lime" ribbon="maroon" textSize='sm' title='Thanks'>
            <div className="card card-body fadein text-center">
            <h1 className="  my-5 fw-bold text-success">
            <i className="bi bi-check2-all text-success"></i>     Thank  You  <i className="bi bi-emoji-smile-fill"></i>
                </h1>

            <p className="h4 my-4 text-center text-success">
                <i className="bi bi-send-check text-success"></i>   {location.state && location.state.message ? location.state.message :"Your form has been submitted successfully."} <br/>
                  <Link to="/"  className="btn btn-outline-success my-5" >
                <i className="bi bi-chevron-double-right "></i>
                   Go back home
                </Link>
            </p>
            </div>
          </MaroonCard>
  );
};
