import { useAsyncError, isRouteErrorResponse, useRouteError} from "react-router-dom";
import { GoHome } from "./gohome";
// https://reactrouter.com/en/main/route/error-element

// global error handling 
window.onerror = function (message, source, lineno, colno, error) {

   // Log the error details to a remote server
   const errorData ={message,source,lineno,colno,error: error ? error.stack : null};

    // Log the error details or send them to a logging service
    console.log('Global Error: ==============================>>>');
    console.error(errorData);

  // Send errorData to a logging service (e.g., via an HTTP request)

  // Return true to prevent the default browser error handling
  return true;

/**message: A string containing the error message.
source: A string representing the URL of the script where the error occurred.
lineno: An integer indicating the line number where the error occurred.
colno: An integer indicating the column number where the error occurred.
error: An error object containing additional information about the error (if available).
**/
};

export function RootBoundary() {
    
    const error = useRouteError();
    const AsyError:any = useAsyncError();
    console.log('RootBoundary errors log: ==================================>>');
    console.error(error || AsyError);

    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return <h2 className="text-center text-danger">This page doesn't exist!</h2>;
      }
  
      if (error.status === 401) {
        return <h2 className="text-center text-danger">You aren't authorized to see this</h2>;
      }
  
      if (error.status === 503) {
        return <h2 className="text-center text-danger">Looks like our API is down</h2>;
      }
  
      if (error.status === 418) {
        return <h2 className="text-center text-danger">🫖</h2>;
      }
    }
  
    return  <div className="text-center text-danger">
    <h1>Oops ! Something went wrong. Please try again later.</h1>
    <h2>{AsyError.status}</h2>
    <p>{AsyError.statusText}</p>
    {AsyError.message?.message && <p>{AsyError.message}</p>}
  </div>;
  }

function ErrorBoundary() {
    const error = useRouteError();
    const AsyError:any = useAsyncError();
    //if (isRouteErrorResponse(error) && error.status === 401)
    if (isRouteErrorResponse(error)) {
      return (
        <div className="text-center">
          <h1>Oops!</h1>
          <h2>{error.status}</h2>
          <p>{error.statusText}</p>
          {error.data?.message && <p>{error.data.message}</p>}
        </div>
      );
    } else {
      return <div>Oops <br/> {AsyError.status} -- {AsyError.message} </div>;
    }
  }
  

export const ContextualError =()=>{
    const error:any = useAsyncError();
    
 return <div className='mx-auto text-center'>
    <p className='text-danger text-center lead'>
        Oops! There was an error. <br/> 
        {error && error.message}
        </p>
        
        <GoHome/>
        </div>;
}
