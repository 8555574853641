//import website from "../assets/icons/icons8-website-150.png"
import azure from "../assets/icons/icons8-azure.svg"
import google from "../assets/icons/icons8-google-cloud.svg"
import github from "../assets/icons/icons8-github-48.png"
import linkedin from "../assets/icons/icons8-linkedin-48.png"
import facebook from "../assets/icons/icons8-facebook-48.png"
import youtube from "../assets/icons/icons8-youtube-48.png"
import twiter from "../assets/icons/icons8-twitter-48.png";
import whatsapp from "../assets/icons/icons8-whatsapp-48.png";


export default function Social() {


  return (
    <div className="socials">
      <a target="_blank" className='nav-link' title='Credly profile' href="https://www.credly.com/users/tuban" rel='noreferrer noopener' ><i className="bi bi-award-fill text-primary h2"></i></a>
      <a href="https://docs.microsoft.com/en-us/users/tuban" target="_blank" rel='noreferrer noopener'
      ><img
          src={azure}
          alt="Azure"
          title='Microsoft Azure'
          className="socicon"
        /></a>
      <a href="https://g.dev/tuban" target="_blank" rel='noreferrer noopener'
      ><img
          src={google}
          alt="Google"
          title='Google Cloud'
          className="socicon"
        /></a>
      <a href="https://github.com/Khalillof" rel='noreferrer noopener' target="_blank"
      ><img src={github} alt="Github" className="socicon"
        /></a>
      <a href="/" target="_blank"
      ><img
          src={whatsapp}
          alt="Whatsapp"
          title='Whatsapp'
          className="socicon"
        /></a>
      <a href="/" target="_blank"
      ><img
          src={twiter}
          alt="Twitter"
          title='Twitter'
          className="socicon"
        /></a>

      <a href="https://www.facebook.com" rel='noreferrer noopener' target="_blank"
      ><img
          src={facebook}
          alt="Facebook"
          title='Facebook'
          className="socicon"
        /></a>
      <a href="https://www.linkedin.com/in/tuban" rel='noreferrer noopener' target="_blank"
      ><img src={linkedin} alt="Linkedin" title="Linkedin" className="socicon"
        /></a>
    </div>
  );
}
