import { Link } from 'react-router-dom';
import MaroonCard from '../components/MaroonCard';
import hero from '../assets/images/last.png';
import '../css/resume.css'
import Clouds from '../components/clouds';

export function Portfolio(props: any) {

const badges = ["694e02b4-21e9-48da-af43-efc1036f4a81","3031ec70-150e-4653-9a9a-1450b09928a4","bb6812ef-8a9c-482c-b92a-044885f25ba3","587b592a-1803-479d-8762-b82ae87b4c97","d80a247e-a64e-438a-ace4-516c6afca3ba", "1265becf-7d26-462a-8847-c2c533cd32d4", "ea40178f-0c05-4923-ab2f-697b03efca3c","c3d55f31-bb69-4883-a2d8-d379c17fc4a0"];

const rendeBadges = badges.map((id)=> <div key={id} className='col-lg-4 col-md-4 col-sm-6'><div className='ratio ratio-4x3'> <iframe   name='acclaim-badge' id={'embedded-badge-'+id} scrolling='no' src={'https://www.credly.com/embedded_badge/'+ id}  title='View my verified achievement on Credly.' ></iframe> </div></div>);


return ( 
      <div className="row fadein">

        <div className="col-md-3" >
          <div id="sideNav" className="position-sticky" style={{ top: 10 }}>

            <MaroonCard  title="Resume">

              <Link to="/" className="nav-link rounded-5 py-3">

                <img className="img-fluid img-profile rounded-circle mx-auto d-block" height={100} src={hero} alt="" />

              </Link>
              <p className="lead text-center"> Orchestrate, design, build &amp; monitor cloud infrastructure solutions, apps modernization, degital trasfomation &amp; cloud migration.
         </p>
              <ul className="nav flex-column d-none d-md-block">
              <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger" href="#info"> Basic information</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger" href="#summary"> Summary </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger "   href="#skills">KILL SET &amp;  TECHNOLOGIES</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger"  href="#award">Awards &amp; Certification</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger"  href="#experience">Experience</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger"  href="#education">Education</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger"  href="#interests">Interests</a>
                </li>
              </ul>

              <figure className='small'>
                <blockquote className="blockquote">
                  <p>Software design is a dimensions for a creative processe of problem solving.!</p>
                </blockquote>
                <figcaption className="blockquote-footer">
                 2017 - <cite title="Source Title">Khalil Tuban</cite>
                </figcaption>
              </figure>


            </MaroonCard ></div>
        </div>

        <div className="col-md-9">
          <MaroonCard title="Resume">

          <section className='pb-3 w-100' id='info' title='info'>
          <h2 className="mb-0">Mr. Khalil
                <span className="text-primary">Tuban</span>
              </h2><h5 className="mb-0">Software Engineer</h5>
              <p className='lead my-0'>Certified - DevOps <span className="vr"></span> Data <span className="vr"></span> AI &amp; Cloud Engineer</p>
              <small className=' my-0 text-secondary small'>BIRMINGHAM , UNITED KINGDOM  </small> <br/>
           <a className=' text-secondary small' href="mailto:khalil@tuban.me" >Email me <i className="bi bi-envelope-paper-heart-fill text-primary"></i></a>
          <hr className='border border-danger'/>
              <Clouds />

              
          </section>

           <section className="pb-3 w-100 " id="summary">
            <a className="btn btn-sm btn-outline-primary" data-bs-toggle="collapse" href="#collapsesummary" role="button" aria-expanded="false" aria-controls="collapsesummary">
              <h5 className="fst-italic"> Summary  </h5>
           </a>
            <div className="collapse fadein" id="collapsesummary">

                <h4 className="fst-italic">Summary :</h4>
                <ul className="">
                  <li className=""> Multi stacks software engineer with significant experience, building dynamic &amp; high performant applications, deploy to on-premises &amp; cloud servers.</li>
                  <li className=""> Orchestrate, design, build &amp; monitor Cloud infrastructure solutions.</li>
                  <li className=""> Implementing &amp; maintaining CI/CD pipelines, using best modern practices DevOps &amp; SRE.</li>
                  <li className=""> Debug, diagnose &amp; troubleshoot issues within the IT system at all levels of the stack &amp; participate in finding appropriate optimal resolutions.</li>
                  <li className="">  Advance knowledge on Linux operating systems &amp; Linux server administration.</li>
                  <li className="">Strong academic foundation in SDLC, applications design architectures &amp; development methodologies.</li>
                  <li className="">Experience working in an agile environment, using agile, scrum and Kanban practices.</li>
                  <li className=""> Flexible with Ability to adapt to many different domains and always eager to learn new technologies</li>
                </ul>
              
              </div>
           </section>

            <section className="pb-3 w-100" id="skills">
            <a className="btn btn-sm btn-outline-primary" data-bs-toggle="collapse" href="#collapseSkills" role="button" aria-expanded="false" aria-controls="collapseSkills">
            <h5 className="fst-italic ">KILL SET &amp;  TECHNOLOGIES</h5>
           </a>
            <div className="collapse fadein" id="collapseSkills">
              
              <ul className='list-unstyled'><li> 
              <h5 className="fst-italic">Web Frameworks :</h5>
              <ul>
                <li className='fst-italic'>
                <h6 className="fst-italic">Csharp (C#) ASP.NET Core :</h6>
                  <ul >
                    <li> MVC / Razor Pages / Web API / SPA / ORM – Entity framework</li>
                  </ul>
                </li>
                <li className='fst-italic'>
                <h6 className="fst-italic">Python - Django / Flask</h6>
                  <ul>
                    <li> Web application, RESTful API / SPA / Command line applications</li>
                  </ul></li>
                <li className='fst-italic'>
                <h6 className="fst-italic">JavaScript / Typescript / NodeJs</h6>
                  <ul >
                    <li>NodeJs, Expressjs web application, API and SPA </li>
                    <li>client side using React, Angular Other popular JavaScript frameworks and libraries</li>
                  </ul></li>
              </ul>
              </li>
              <li className='fst-italic'>
              <h5 className="fst-italic">Databases :</h5>
              <ul>
              <li className='fst-italic'>
                <h6 className="fst-italic">SQL Databases :</h6>
                  <ul >
                    <li>SQL Server, MYSQL, PostgreSQL and SQLite</li>
                  </ul></li>
                  <li className='fst-italic'>
                <h6 className="fst-italic">NOSQL Databases :</h6>
                  <ul >
                    <li>MongoDb, Azure Cosmos-DB</li>
                  </ul></li>
                  
                </ul>
              </li>
              <li>
              <h5 className="fst-italic"> Security &amp; Authentication &amp; Authorisation :</h5>
                <ul >
                  <li> Open Id Connect / auth2, JSON Web Token -JWT and X509 Certificate based authentication</li>
                  <li> Microsoft Identity platform Authentication libraries ( MSAL)</li>
                  <li> Implement SRE & DvOps , logging, tracing and monitoring metrics systems.</li>
                </ul>
              </li>
              <li>
              <h5 className="fst-italic"> Cloud Computing :</h5>
              <ul >
                  <li> Certified Microsoft Azure DevOps Engineer Expert </li>
                  <li> Certified Microsoft Azure Artificial Intelligence Engineer </li>
                  <li> Certified Microsoft Azure Developer  </li>
                  <li> IT Automation with python - Google  </li>
                  <li> Familiarity with Google Cloud  </li>
                </ul>
              </li>
              <li>
              <h5 className="fst-italic"> Artificial Intelligence :</h5>
                <ul >
                  <li> build, manage, and deploy AI solutions </li>
                  <li> Plan and manage an Azure Cognitive Services solution  </li>
                  <li> Implement Computer Vision solutions  </li>
                  <li> Implement natural language processing solutions  </li>
                  <li> Implement knowledge mining solutions  </li>
                  <li> Implement conversational AI solutions   </li>
                </ul>
              </li>
              <li>
              <h5 className="fst-italic"> Micro-Service, Configurations management &amp; CI/CD tools :</h5>
                <ul >
                  <li>   Shell scripting, Python, Git, Docker, Kubernetes, GitHub &amp; GitHub Actions, Azure DvOps, Gitlab, terraform, Jenkins and more.</li>
                </ul>
              </li>
              <li>
              <h5 className="fst-italic"> Software architectural design patterns :</h5>
                <ul >
                  <li> Model View Controller (MVC)</li>
                  <li>Model View View Model (MVVM)</li>
                  <li>Model View Presenter ( MVP)</li>
                </ul>
              </li>
              <li>
              <h5 className="fst-italic"> Cyber Security :</h5>
                <ul >
                  <li> CyberSecurity mindset& techniquessuch as automated static code analysis, vulnerability scan, penetration testing &amp; construction of secure software.</li>
                </ul>
              </li>
              </ul>

              <h5 className="fst-italic">Programming Languages &amp; Tools</h5>
              <ul >
                  <li> JavaScript – TypeScript / Python / C sharp C# / Shell scripting –and recently experiencing Golang – Go.</li>
                </ul>
            {/* 
              <div className="subheading mb-3">Workflow</div>
              <ul className="fa-ul mb-0">
                <li>
                  <i className="bi bi-check-all"></i>
                  Mobile-First, Responsive Design</li>
                <li>
                  <i className="bi bi-check-all"></i>
                  Cross Browser Testing &amp; Debugging</li>
                <li>
                  <i className="bi bi-check-all"></i>
                  Cross Functional Teams</li>
                <li>
                  <i className="bi bi-check-all"></i>
                  Agile Development &amp; Scrum</li>
              </ul>
            */}
            </div>
            </section>

            <section className="pb-3 w-100" id="awards">
             
              <a className="btn btn-sm btn-outline-primary" data-bs-toggle="collapse" href="#collapseAward" role="button" aria-expanded="false" aria-controls="collapseAward">
              <h5 className="fst-italic">Awards &amp; Certifications</h5>
           </a>
            <div className=" collapse fadein" id="collapseAward">

             <h5 className='my-2'> <a title='Go To Credly' href='https://www.credly.com/users/tuban'>Credly</a></h5>
              

          <div className="container-fluid px-0 py-3">
            <div className='row'>
            {rendeBadges}       
          </div>
          </div>
              {/** 
    <h2 className="mb-5">Awards &amp; Certifications</h2>
    <ul className="fa-ul mb-0">
      <li>
        <i className="bi bi-trophy text-warning"></i>
        Google Analytics Certified Developer</li>
      <li>
        <i className="bi bi-trophy text-warning"></i>
        Mobile Web Specialist - Google Certification</li>
      <li>
        <i className="bi bi-trophy text-warning"></i>
        1<sup>st</sup>
        Place - University of Colorado Boulder - Emerging Tech Competition 2009</li>
      <li>
        <i className="bi bi-trophy text-warning"></i>
        1<sup>st</sup>
        Place - University of Colorado Boulder - Adobe Creative Jam 2008 (UI Design Category)</li>
      <li>
        <i className="bi bi-trophy text-warning"></i>
        2<sup>nd</sup>
        Place - University of Colorado Boulder - Emerging Tech Competition 2008</li>
      <li>
        <i className="bi bi-trophy text-warning"></i>
        1<sup>st</sup>
        Place - James Buchanan High School - Hackathon 2006</li>
      <li>
        <i className="bi bi-trophy text-warning"></i>
        3<sup>rd</sup>
        Place - James Buchanan High School - Hackathon 2005</li>
    </ul>*/}
            </div>
            </section>

            <section className="pb-3 w-100" id="experience">
              
              <a className="btn btn-sm btn-outline-primary" data-bs-toggle="collapse" href="#collapseExperience" role="button" aria-expanded="false" aria-controls="collapseExperience">
              <h5 className="fst-italic">Experiences</h5>
           </a>
            <div className="collapse fadein" id="collapseExperience">
              
              <h5>TO DO</h5>
              {/* <h2 className="mb-5">Experience</h2>

                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div className="resume-content">
                    <h3 className="mb-0">Senior Web Developer</h3>
                    <div className="subheading mb-3">Intelitec Solutions</div>
                    <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.</p>
                  </div>
                  <div className="text-md-right">
                    <span className="text-primary">March 2013 - Present</span>
                  </div>
                </div>

                <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div className="resume-content">
                    <h3 className="mb-0">Web Developer</h3>
                    <div className="subheading mb-3">Intelitec Solutions</div>
                    <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.</p>
                  </div>
                  <div className="resume-date text-md-right">
                    <span className="text-primary">December 2011 - March 2013</span>
                  </div>
                </div>

                <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div className="resume-content">
                    <h3 className="mb-0">Junior Web Designer</h3>
                    <div className="subheading mb-3">Shout! Media Productions</div>
                    <p>Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration.</p>
                  </div>
                  <div className="resume-date text-md-right">
                    <span className="text-primary">July 2010 - December 2011</span>
                  </div>
                </div>

                <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
                  <div className="resume-content">
                    <h3 className="mb-0">Web Design Intern</h3>
                    <div className="subheading mb-3">Shout! Media Productions</div>
                    <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.</p>
                  </div>
                  <div className=" text-md-right">
                    <span className="text-primary">September 2008 - June 2010</span>
                  </div>
                </div>
  */}
           </div> </section>

            <section className="pb-3 w-100" id="education">

            <a className="btn btn-sm btn-outline-primary" data-bs-toggle="collapse" href="#collapseEducation" role="button" aria-expanded="false" aria-controls="collapseEducation">
              <h5 className="fst-italic">Education</h5>
           </a>
            <div className="collapse fadein" id="collapseEducation">

              <h5>TO DO</h5>
              {/*
                <h2 className="mb-5">Education</h2>

                <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div className="resume-content">
                    <h3 className="mb-0">University of Colorado Boulder</h3>
                    <div className="subheading mb-3">Bachelor of Science</div>
                    <div>Computer Science - Web Development Track</div>
                    <p>GPA: 3.23</p>
                  </div>
                  <div className="resume-date text-md-right">
                    <span className="text-primary">August 2006 - May 2010</span>
                  </div>
                </div>

                <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
                  <div className="resume-content">
                    <h3 className="mb-0">James Buchanan High School</h3>
                    <div className="subheading mb-3">Technology Magnet Program</div>
                    <p>GPA: 3.56</p>
                  </div>
                  <div className="resume-date text-md-right">
                    <span className="text-primary">August 2002 - May 2006</span>
                  </div>
                </div>
*/}

           </div> </section>

            <section className="pb-3 w-100" id="interests">
              <a className="btn btn-sm btn-outline-primary" data-bs-toggle="collapse" href="#collapseInterests" role="button" aria-expanded="false" aria-controls="collapseInterests">
              <h5 className="fst-italic">Interests</h5>
           </a>
            <div className="collapse fadein" id="collapseInterests">
              <p>Apart from being a web developer, I enjoy most of my time being outdoors. In the winter, I am an avid skier and novice ice climber. During the warmer months here in Colorado, I enjoy mountain biking, free climbing, and kayaking.</p>
              <p className="mb-0">When forced indoors, I follow a number of sci-fi and fantasy genre movies and television shows, I am an aspiring chef, and I spend a large amount of my free time exploring the latest technology advancements in the front-end web development world.</p>
            </div>
            </section>
          </MaroonCard>
        </div>


      </div>

  );
}
