import { Link } from 'react-router-dom'
import { ISiteContextValues } from '../../types/utils'
import { useShopState } from '../../utils/context'
import { Recipe } from './Recipe'
import { IProduct } from './reducers/cartReducer'

export const ShoppingCart = (props: { cnx: ISiteContextValues }) => {
    const { state, RemoveItem, AddQuantity, SubtractQuantity } = useShopState(props.cnx);

    let addedItems = state.addedItems.length ?
        (
            state.addedItems.map((item: IProduct) => {
                return (

                    <div className="col-md-3" key={item.id}>
                        <div className='card'>
 <img className=' d-block mx-auto' src={item.img} style={{ width:"100px"}} alt={item.title} />



                            <div className="card-body"> 
                            <h5 className="card-title">{item.title} </h5>

                                <p className="card-text" >{item.desc}</p>

                                <div className='row'>
                                <div className='col'>
                                <p className="card-text" ><b>Price: <span className="badge text-bg-primary"><i className="bi bi-currency-pound"></i> {item.price}</span></b></p>
                                </div>
                                <div className='col'>
                                <b className='float-end'>Qnty: <span className="badge text-bg-secondary "> {item.quantity}</span></b>
                                </ div>
                            </div>
                            <div className='row'><div className='col'>
                                <div className="btn-group w-100 pt-2" role="group" aria-label="Basic mixed styles example">
                                    <button type="button" className="btn btn-outline-danger btn-sm " title='remove' onClick={() => { RemoveItem(item.id) }}><i className="bi bi-trash3"></i></button>
                                    <Link type="button" className={"btn btn-outline-info btn-sm "+(item.quantity === 1 ? 'disabled': '')} to="/cart"><i className="bi bi-dash-square-fill h4" onClick={() => { SubtractQuantity(item.id) }}></i></Link>
                                    <button type="button" className="disabled btn btn-primary btn-sm fw-bold" >{item.quantity}</button>
                                    <Link type="button" className="btn btn-outline-warning btn-sm " to="/cart"><i className="bi bi-plus-square-fill h4" onClick={() => { AddQuantity(item.id) }}></i></Link>
                                </div>
                                </div></div>
                            </div>


                        </div>
                    </div>

                )
            })
        ) :

        (
            <p className='text-center'>Nothing.</p>
        )
    return (
        <div className="w-100">
            <h5 className='text-center'>You have ordered:</h5>
            <div className="row">
                {addedItems}
            </div>

            <Recipe cnx={props.cnx} />
        </div>
    )
}


