import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import { UserLikes, RenderComments, Loading } from "../../components";
import { DynamicLinks, isIn, ToHTML } from "../../components/helpers";
import { useModelLayoutContext } from "./default_model_layout";
import { renderTemplate } from "./use_renders";


export function RenderModel() {
 
    const { cnx, viewData} = useModelLayoutContext()!
    const { routeName, name } = viewData;
    const paramId = useParams()[name+'Id'];
    const {pathname,search,state:locState} = useLocation();
    const currentUrl = pathname.slice(1) + search;
  
    const [state, setState] = useState<{ model: any, htmlString: string | null }>({ model: null, htmlString: null });
  
    const navigate = useNavigate();
  
  useEffect(()=> {
  
      const setStateAndCache = (data: Partial<{ model: any, htmlString: string | null }>)=>{
        let _dd = { ...state, ...data };
        setState(_dd);
        cnx.appCaches.set(currentUrl, _dd);
    
      };
  
      if (!state.model || (state.model._id !== paramId)) {
        if (cnx.appCaches.isIn(currentUrl)) {
          setState(cnx.appCaches.get(currentUrl))
        } else if (locState && locState.model?._id === paramId) {
          
          let htmlString: any = renderTemplate(viewData.modelTemplate,locState.model);
          setStateAndCache({ model: locState.model, htmlString })
  
        }
        else {
  
          cnx.ajax.get(currentUrl)
            .then(({ data }) => {
           
              if (data) {
                let htmlString: any = renderTemplate(viewData.modelTemplate,data);
                setStateAndCache({ model: data, htmlString })
              } else {
                setState({ model: null, htmlString: null })
                navigate('/notfound')
              }
            })
        }
      }
    }, [locState, viewData, navigate, paramId, routeName, state.model, currentUrl])
  
  
  
    if(state.model && state.htmlString ){
    return <> 
  
    
         <div className="btn-toolbar float-end w-100" role="toolbar" aria-label="Toolbar groups">
    
         <div className='btn-group btn-group-sm me-3' role="group">
         {  isIn(viewData.plugins,'like') &&  <UserLikes cnx={cnx} modelid={state.model._id} />}
         
         {cnx.isAdmin ? <Link to={'/'+routeName+'/'+state.model._id + '/update'} state={{ model: state.model}} className='btn btn-outline-primary '> Edit {name}</Link> : ""}
          </div>
          
         </div> 
      
     <DynamicLinks {...{routeName,appCaches:cnx.appCaches,cacheKey: currentUrl,navigate, dataList:false}}>
        <ToHTML __html={state.htmlString} />
        {isIn(viewData.plugins,'comment') && < RenderComments modelid={state.model._id} cnx={cnx} />}
     </DynamicLinks>
     </>
    }
    return <Loading />
  }