import { Link } from 'react-router-dom';
import hero from '../assets/images/last.png';
import MaroonCard from '../components/MaroonCard'

export default function Banner(){

    return (
      
<MaroonCard size='xl' textSize='lg' ribbon='maroon' title='Welcome'>
  
    <div className="row flex-lg-row-reverse align-items-center g-5 pb-4">
      <div className="col-10 col-sm-8 col-lg-6 ">
        <img src={hero} className="d-block  img-fluid" alt="Bootstrap Themes" width="700" height="500" />

      </div>
      <div className="col-lg-6">
        <h2 className="">WE DO !</h2>

        <p className="lead"> 
        Orchestrate, design, build & monitor cloud infrastructure solutions, apps modernization, degital trasfomation &amp; cloud migration.<br/>
        <strong className='h3'>I am,</strong> Certified DevOps, Data, Artificial Intelligence and Cloud Software Engineer. <br/> Speciality in Software development with focus on solutions orchestration &amp; processes automations . Passionate technology enthusiast, blogger who likes to learn &amp; keep up to date with technology innovations.</p>

        <div className="w-100 text-center pt-4">
          
          <Link to="/posts" className="btn btn-primary btn-lg   me-2 "><i className="bi bi-newspaper"> Blog </i> </Link>
          <Link to="/portfolio" className="btn btn-info btn-lg  "><i className="bi bi-person-workspace"> Portfolio </i> </Link>
         {/**  <Link to="/hireme" className="btn btn-success  d-inline"><i className="bi bi-emoji-heart-eyes"> Hire me</i></Link>*/}
        </div>
      </div>
    </div>

  </MaroonCard>
    );
}
