
import {ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,ADD_SHIPPING, SUBSTRACT_SHIPPING} from './action-types/cart-actions'

//add cart action
export const addToCart= (id:any)=>{
    return{
        type: ADD_TO_CART,
        id
    }
}
//remove item action
export const removeItem=(id:any)=>{
    return{
        type: REMOVE_ITEM,
        id
    }
}
//subtract qt action
export const subtractQuantity=(id:any)=>{
    return{
        type: SUB_QUANTITY,
        id
    }
}
//add qt action
export const addQuantity=(id:any)=>{
    return{
        type: ADD_QUANTITY,
        id
    }
}

export const addShipping=(id:any=0)=>{
    
    return{
        type: ADD_SHIPPING,
        id
    }
}

export const substractShipping=(id:any=0)=>{
    return{
        type: SUBSTRACT_SHIPPING,
        id
    }
}
