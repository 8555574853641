import {useMemo } from "react";
import history from '../utils/history';

import {RouteObject, redirect, createBrowserRouter} from "react-router-dom";
import * as Pages  from "../pages";

import { Products, ShoppingCart } from "../shopping";
import {IModelViewData, IViewData } from "../types/utils";
import { useSiteContext } from "./context";
//import { LoginOut } from "../pages/Login";

import {OAuthCallback} from './AuthService'
import { isIn } from "../components/helpers";



export default function useAppRoues(): any{
const cnx = useSiteContext()!

return useMemo(() => {

  const defaultAction:any =  async (viewData: IViewData) => {

    return async function (props: { params: any, request: Request }) {
    
      const { params, request } = props;
      let formData: any = await request.formData();
      const fValues = Object.fromEntries(formData);
      //await updateContact(params.contactId, updates);
      //return redirect(`/contacts/${params.contactId}`);
    
      switch (request.method) {
        case "POST": {
    
          let result = await cnx.ajax.post(viewData.routeName , fValues)
          return redirect(viewData.routeName + '/' + result?.data._id);
        }
        case "PUT": {
    
          let result = await cnx.ajax.put(viewData.routeName + fValues._id , fValues)

          return redirect(viewData.routeName + '/' + result?.data._id);
        }
        case "DELETE": {
    
          let result = await cnx.ajax.deleteReq(viewData.routeName + fValues._id );
          return result?.data._id;
        }
        default: {
          throw new Response("", { status: 405 });
        }
      }
    }
    }
    
    const authLoader = (action: string, viewData: IViewData,getform?: boolean) => {
    return  async (props: { params: any, request: Request }) => {
     
      const { routeName, name } = viewData;
    
    let modelform = getform ? (await cnx.ajax.get(routeName + '/form'))?.data : name;
    let _auth =  isIn(viewData.userAuth,action);
    let _admin = isIn(viewData.adminAuth,action);
    
    let _url = new URL(props.request.url);
    let url = _url.pathname+_url.search;
    // push to history
    history.push(url);
    
    if (_admin || _auth) {
    
      if (!cnx.auth.isAuthenticated) {
        return redirect(`/login?returnUrl=${_url.pathname+_url.search}`)
      }else{
        if ( _admin && !cnx.isAdmin) {
        return  redirect('/requireadmin')
      }
    
      }
    }
    
    return modelform;
    
    }
  }
  //============================================================================================

let _routes: RouteObject = {
  path: "/",
  element: <Pages.Layout />,
  errorElement: <Pages.RootBoundary />,
  children: [
    {
      index: true,
      element:  <Pages.Home/>,
    },
    {
      path: "home",
      element: <Pages.Home />,
    },
    {
      path: "portfolio",
      element: <Pages.Portfolio />,
      //loader: appLoader,
    }, {
      path: "pricing",
      element: <Pages.Pricing />
    },
    {
      path: "products",
      element: <Products cnx={cnx} />
    },
    {
      path: "cart",
      element: <ShoppingCart cnx={cnx} />
    },
    {
      path: "checkout",
      element: <Pages.CheckOut />
    },

    {
      path: "login",
      element: <Pages.LoginOut />
    },
    {
      path: "signup",
      element: <Pages.Signup />
    },
    {
      path: "login",
      element: <Pages.LoginOut />
    },
    {
      path: "/oauth/signin-cb",
      element: <OAuthCallback/>

    },
    {
      path: "unauthorized",
      element: <Pages.UnAuthorized />
    },
    {
      path: "forgetPassword",
      element: <Pages.ForgotPassword />
    },
    {
      path: "requireadmin",
      element: <Pages.RequireAdmin />
    },
    {
      path: "thankyou",
      element: <Pages.Thanks />
    },
    {
      path: "test",
      element: <Pages.Test />
    },
    {
      path: "notfound",
      element: <Pages.NotFound />
    },
    {
      path: "error",
      element: <Pages.ErrorPage />
    }

  ],
};

  if (cnx.viewsData?.length) {

    let dynamicRoutes: RouteObject[]=[];

    for (let viewData of cnx.viewsData) {

  
      const _paramId = ':'+viewData.name+'Id';

      dynamicRoutes.push({
        path: viewData.routeName,
        element: <Pages.DefaultModelLayout key={viewData.name+'Layout'} viewData={viewData} />,
        errorElement: <Pages.RootBoundary />,
        children: [
          {
            
            index: true,
            element: <Pages.RenderList  />,
            loader: authLoader('list', viewData)

          },
          {
            path: "list",
            element: <Pages.RenderList  />,
            loader: authLoader('list', viewData)
          },
          {
            path: _paramId,
            element: <Pages.RenderModel />,
            loader: authLoader('get', viewData)
          },
          {
            path: "create",
            element: <Pages.CreateModel />,
            loader: authLoader('create', viewData, true),
            action: defaultAction(viewData),
          },
          {
            path: _paramId + '/update',
            element: <Pages.EditModel  />,
            loader: authLoader('update', viewData, true),
            action: defaultAction(viewData)
          },
          {
            path: _paramId + '/delete' ,
            element: <Pages.DeleteModel />,
            loader: authLoader('delete', viewData),
            action: defaultAction(viewData),
          }
        ]
      })
    }
    _routes.children = [..._routes.children!, ...dynamicRoutes];
  }

  let browserRoutes:any = createBrowserRouter([_routes]);
  let listOfRoutespath: string[] = [];

  if(browserRoutes?.routes.length){

    for (let r of browserRoutes.routes) {
     if (r.children){
       let m = r.children.map((e:any)=> e.path);
   
       listOfRoutespath.push(...m);
     }else {
      r.path && listOfRoutespath.push(r.path);
     }
     }
     }
    // store
    cnx.appCaches.set('routesPath',listOfRoutespath)
    


  return browserRoutes;
}, [cnx])

}
