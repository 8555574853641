
import {Link} from 'react-router-dom';


//import NotFoundImage from "../assets/img/illustrations/404.svg";


export const UnAuthorized = () => {
  return (
          <div className="row fadein">
            <div className="col-xs-12 text-center mx-auto fadein">
                {/** 
                <Link to="/" className="card-link">
                  <img src={NotFoundImage} className="img-fluid w-75" alt="hiii" />
                </Link>
                */}
                <h1 className="text-danger mt-5">
                Unauthorized access <span className="fw-bolder">401</span>
                </h1>
                <p className="lead my-4 text-danger">
                  Oops! <strong className="text-danger">Unauthorized access 401</strong> Looks like you do not have access privilege to this resouce. If you think this is a
                  error form us, please tell us.
            </p>
                <Link to="/"  className="btn btn-outline-primary" >
                <i className="bi bi-chevron-double-right mt-2"></i>
                  Go back home
                </Link>

            </div>
          </div>
  );
};
