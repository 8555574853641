import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { useEffect } from 'react';
import { AuthContextProps , withAuthenticationRequired} from "react-oidc-context";
import { Loading } from '../components';
//var redirectUrl = process.env.NODE_ENV === 'production'  ? window.location.origin : 'http://localhost:3000';

export const oidcConfig: UserManagerSettings = {
    authority: 'https://auth.tuban.me/',
    client_id: '122ba6762a06439abb6778739310277f',
    redirect_uri: window.location.origin + "/oauth/signin-cb",
    silent_redirect_uri: window.location.origin + '/oauth/signin-cb',
    post_logout_redirect_uri: window.location.origin,
    response_type: 'code',
    scope: 'profile roles email',
    loadUserInfo: true,
    extraQueryParams: { returnurl: window.location.pathname + window.location.search || "" }
    //userStore: new WebStorageStateStore({ store: window.localStorage })
};


const userManager = new UserManager(oidcConfig);



export function OAuthCallback() {

    // const location = useLocation();
    // const navigate = useNavigate();
    // const searchQuery = useQuery(location as any);
    // const returnUrl = searchQuery.get('returnUrl');

    useEffect(() => {

        //console.log("location", location.pathname+location.search)
        // console.log("location state :", location.state)
        //navigate(returnUrl ||'/');
        //navigate('/');
    })

return <Loading/>
}

export const ProtectedRoute = (props:{Elem: any})=>  withAuthenticationRequired(props.Elem, {
    OnRedirecting: () => (<div className='text-center text-primary text-bold'>Redirecting to the login page...</div>)
  });

// renews token using refresh token
export async function renewToken() {
    const user = await userManager.signinSilent();

    return user;
}


export async function logout(auth: AuthContextProps) {

    auth.removeUser();
    auth.clearStaleState();
    //await auth.signoutRedirect();
}

// This function is used to access token claims
// `.profile` is available in Open Id Connect implementations
// in simple OAuth2 it is empty, because UserInfo endpoint does not exist
// export async function getRole() {
//     const user = await getUser();
//     return user?.profile?.role;
// }

// This function is used to change account similar way it is done in Google
// export async function selectOrganization() {
//     const args = {
//         prompt: "select_account"
//     }
//     await userManager.signinRedirect(args);
// }