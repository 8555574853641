
// https://gomakethings.com/html-templates-with-vanilla-javascript/

export function normalizeParams(params: Record<string, any>) {
	let temParams: Record<string, any> = {};

	// Loop through each wizard
	for (let [key, value] of Object.entries(params)) {
		let _type = typeof value;
		
		if (_type === 'object') {
			//normalizeParams(value);
			for(let k in value){
			temParams[[key]+'.'+[k]] = String(value[k]);
			}

		} else {
			temParams[key] = _type === 'string' ? value : String(value);
		}
	}

	return temParams;
	//return {names,values}
}
export  function renderTemplate(templateStr: string, data: any): string | string[] {
	
	if(Array.isArray(data)){
	  return data.map((_params:any)=> genTemplate(templateStr,_params));		
	}else{
		//let _da = normalizeParams(data)
	 return	genTemplate(templateStr,data)
	}
}

export function renderTemplateWithCb(templateStr: string, data: any, callback?:(item:any)=>void): string | string[]{
	if(Array.isArray(data)){
		return data.map((_params:any)=> {

		 callback  && callback(_params);

		 return	genTemplate(templateStr,_params)
		});		
	  }else{
		callback  && callback(data);
	   return	genTemplate(templateStr,data)
	  }

}
/**
 * Get a template from a string
 * https://stackoverflow.com/a/41015840
 * @param  {String} templateStr    The string to interpolate
 * @param  {Object} params The parameters
 * @return {String}        The interpolated string
 */
export function genTemplate(templateStr: string, params: Record<string, any>): string {
	//const {names,values} = params
	let names = Object.keys(params);
	let values = Object.values(params);
	return new Function(...names, `return \`${templateStr}\`;`)(...values);
}

/*
const template = 'Example text: ${text}';
const result = genTemplate(template,{
  text: 'Foo Boo'
});
console.log(result); //Example text: Foo Boo
*/
