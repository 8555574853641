import { useEffect, useRef } from 'react'
import { ISiteContextValues } from '../../types/utils';
import { useShopState } from '../../utils/context';
import { IShopState } from '../components/reducers/cartReducer'

export const Recipe = (props: { cnx: ISiteContextValues }) => {
    const shipping = useRef<HTMLInputElement>(null);
    const { state, AddShipping, SubstractShipping } = useShopState(props.cnx)


    useEffect(() => {
        if (shipping.current && shipping.current!.checked)
            SubstractShipping()
    }, [shipping.current])

    const handleChecked = (e: any) => (e.target.checked ? AddShipping() : SubstractShipping())

    return (
        <div className='col-md-4 mx-auto'>
            <div className="card card body">
                <ul className="list-group">
                    <li className="list-group-item">
                        <input className="form-check-input me-1" type="checkbox" ref={shipping} onChange={handleChecked} id="firstCheckboxStretched" />
                        <label className="form-check-label stretched-link" htmlFor="firstCheckboxStretched"> Shiping :  <span className="badge text-bg-info"> + £6</span></label>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b> Total: <span className="badge text-bg-primary"> £{state.total}</span></b>
                    </li>
                </ul>

                <button type='button' className="btn btn-outline-success">Checkout</button>


            </div>

        </div>

    )
}

