import { Outlet} from 'react-router-dom'
//import  NavMenu  from './NavMenu';
import {Footer} from '../pages'
import {Menu, ScrollTop, Social} from '../components'

export function Layout(props: any) {
 
 
  return (
    <div id='wrapper' className='container-fluid  p-0 fadein'>
      <Menu />
      <div id='main' className='container-xl shadow'>

        <Outlet />
        <Social />
        <ScrollTop />
      </div>
      <Footer />
    </div>
  );
}

