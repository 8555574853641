import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Ajax, useAppFetcher} from '../utils/ajax';
import { useAuth } from "react-oidc-context";
import {
  ISiteContextValues,
  IShopStoreState,
  IAppCaches,
  ISiteState,
  IViewData,
  IModelViewData,

} from '../types/utils'
import { cartReducer, initShopState} from '../shopping/components/reducers/cartReducer'
import { removeItem, addQuantity, subtractQuantity, addToCart, addShipping, substractShipping } from '../shopping';
import { ModelViewtData } from './modelViewData';

export const SiteContext = React.createContext<ISiteContextValues  | null>(null);

export const useSiteContext = () => useContext(SiteContext);

export class AppCaches<T> implements IAppCaches{
  constructor(_store :React.MutableRefObject<Record<string, any>>){
   this.store = _store;
  }
  store:React.MutableRefObject<Record<string, any>>
  set(key:string, data:any){
    if(key && data){
      if(this.store.current[key] !== undefined){
        if (typeof data === 'object'){
           this.store.current[key] =   Array.isArray(data)  ? Array.from(new Set( [...this.store.current[key],...data] )): {...this.store.current[key],...data};
        }else{
          this.store.current[key] = data;
        }
       
      }else{
        this.store.current[key] = data;
      }
    }
  }
  get(key:string) : T | null { return key ? this.store.current[key] : null}
  isIn(key:string):boolean {return key !== undefined && this.store.current[key] !== undefined}
}



export function SiteContextProvider(props: PropsWithChildren) {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.tuban.me/api/' : 'http://localhost:8000/api/';
  const auth = useAuth();
    const [siteState,setSiteState]= useState<ISiteState>({serverDown:false, isOnline:navigator.onLine})
    const  store = useRef<Record<string, any>>({})
    const viewsDataResult = useAppFetcher<IViewData[]>({method:"GET",path:"configs/viewsdata", access_token:(auth.user?.access_token)});
   
    useEffect(()=>{
    if(viewsDataResult.state === 'error'){
    if (viewsDataResult.error.message === "Failed to fetch"){
      setSiteState({...siteState,serverDown:true})
    }else{
      throw viewsDataResult.error;
    }
   }
  },[viewsDataResult.state])

   
  useEffect(() => {

    const handleStatusChange = () => {
     setSiteState({...siteState,isOnline:navigator.onLine})
   }; 
     
     window.addEventListener('online', handleStatusChange);
     window.addEventListener('offline', handleStatusChange);
 
     return () => {
       window.removeEventListener('online', handleStatusChange);
       window.removeEventListener('offline', handleStatusChange);
     };
   }, []);

    const [isAdmin,ajax, appCaches] = useMemo(()=> { 
      var isAdmin = false;
        if(auth.user){
          let roles = auth.user?.profile.roles as string[];
          if(roles && roles.length)
         isAdmin = roles?.indexOf("Administrator")  !== -1;
       }

      return [isAdmin,new Ajax(auth.user?.access_token),new AppCaches(store)]
    },[auth.user]);



  return (
    <SiteContext.Provider value={{baseUrl,viewsData:viewsDataResult.data,isAdmin,auth,siteState,ajax,appCaches}}>
      {props.children}
    </SiteContext.Provider>
  )
}


export function useShopState(cnx:ISiteContextValues): IShopStoreState {

//const [state, setState] = cnx.shopStore!;
const [state, setState] = useReducer(cartReducer, initShopState);

return {
state,
setState,
RemoveItem:(id:any)=>setState(removeItem(id)),
AddQuantity:(id:any)=> setState(addQuantity(id)),
SubtractQuantity:(id:any)=> setState(subtractQuantity(id)),
AddToCart:(id:any)=> setState(addToCart(id)),
AddShipping: ()=> setState(addShipping()),
SubstractShipping: ()=> setState(substractShipping())
}
}

