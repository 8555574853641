import { createElement, useCallback,useState } from 'react';
import { IFormState, IProcessFarm,} from '../types/Iformland';
import { checkboxRadio, FormVarient } from './forms';
import Editor from 'react-simple-wysiwyg';
import { IModelForm } from '../types/utils';
//==============================================================================

export function useProcessFarm(props: IFormState): IProcessFarm {

  var faildElements: HTMLFormElement[] = [];
  var formData: Record<string, any> = {};

  const [farmState, setState] = useState<IFormState>(props);

  const setFarmState = useCallback((anyData?: Partial<IFormState>) => {

    if (anyData) {
      setState({ ...farmState, ...anyData })
    } else {
     // setState(props);
    }
  },[farmState]);
  const useFormChanges = (): [Record<string, any>, React.Dispatch<React.SetStateAction<Record<string, any>>>] => {

    const [state, setState] = useState<Record<string, any>>(props.data)

    const  setChanges = useCallback((e: any)=>{
      let elm = e.target;
      let isRadioCheckBox = "checkbox radio".indexOf(elm.type) !== -1;
      let _name = elm.name;
      let _value = (isRadioCheckBox ? elm.checked : elm.value);
      let _data: Record<string, any> = { ...state, [_name]: _value }

      setState(_data);
      if (elm.type !== 'html') {
        validateChanges(e);
      }


      formData[_name] = _data[_name];
      console.log(_name + ' : ' + _value)

    },[state])


    return [state, setChanges]
  }

  const handleSubmit = useCallback((event: any) => {
    const form: HTMLFormElement = event.currentTarget;

    event.preventDefault();

    // this check just to refresh state of the form so it can be validated by form.checkValidity() and reset failed elements;
    if (faildElements.length) {
      console.log(' found faild elements on top: ' + faildElements.length)
      faildElements.forEach(async (e: any) => checkedUp(e, true));
      faildElements = [];
    }

    // Browser check form validaty state 
    if (!form.checkValidity()) {
      console.log(`form : ${form.id} submited faild validation`)
      // this check is necessary, just to highlight the required feilds, when submit buton clicked without filling all required feilds
      checkFormElements(form);

      return;
    }

    // in House checks after browser HTML5 validation succeeded
    checkFormElements(form, true, (result: boolean, elm: HTMLFormElement) => {

      if (!result) {
        faildElements.push(elm)
        console.log(`${elm.name} : ${elm.value} : this element faild checks after form submission ....! : ` + faildElements.length)
      }
    });

    // final check if found form will be returned and brevious step already populated FaildElements
    if (faildElements.length) {
      console.log(' number of escaped faild elements: ' + faildElements.length)
      return;
    }

    //==== reach final stage all data are clean =============
    // store form values in case form will be returned to user, so values are used to populate the form

    // let formDataSubmitted = Object.fromEntries(new FormData(form).entries());
    console.log('form validation succeeded')

    // clear faild elements
    faildElements = []


    // clean validaty classes
    for (let ee of form.elements) {
      ee.classList.remove('is-valid');
      ee.classList.remove('is-invalid');
    }

    // set form validation to true and clear errors
    //setFarmState({ submitting: true,  errors: null, validated: true, submitted: false, successData: null })

    setFarmState({ submitting: true, data: formData, validated: true, submitted: false })
    //form.reset();
  },[]);


  return {
    farmState,
    setFarmState,
    handleSubmit,
    useFormChanges
  }
}

export interface IHandelFormSubmit{
  validated:boolean
  formData: FormData | null
  errors: string | null
}
export function useHandelFormSubmit():{handelSubmit:(event:any)=> void, submitState:IHandelFormSubmit, setSubmitState:(state:IHandelFormSubmit)=> void } {
  var faildElements: HTMLFormElement[] = [];
  const [submitState, setSubmitState] = useState<IHandelFormSubmit>({validated:false,formData:null, errors:null});

  const handelSubmit = (event: any)=>{ 

    const form: HTMLFormElement = event.currentTarget;

    event.preventDefault();


    // this check just to refresh state of the form so it can be validated by form.checkValidity() and reset failed elements;
    if (faildElements.length) {
      console.log(' found faild elements on top: ' + faildElements.length)

      faildElements.forEach(async (e: any) => checkedUp(e, true));
      faildElements=[];
    }


    // Browser check form validaty state 
    if (!form.checkValidity()) {
      console.log(`form : ${form.id} submited faild validation`)
      // this check is necessary, just to highlight the required feilds, when submit buton clicked without filling all required feilds
      checkFormElements(form);

      return;
    }

    // in House checks after browser HTML5 validation succeeded
    checkFormElements(form, true, (result: boolean, elm: HTMLFormElement) => {

      if (!result) {
        faildElements.push(elm);
        console.log(`${elm.name} : ${elm.value} : this element faild checks after form submission ....! : ` + faildElements.length)
      }
    });

    // final check if found form will be returned and brevious step already populated FaildElements
    if (faildElements.length) {
      console.log(' number of escaped faild elements: ' + faildElements.length)
      return;
    }

    //==== reach final stage all data are clean =============
    // store form values in case form will be returned to user, so values are used to populate the form

    // let formDataSubmitted = Object.fromEntries(new FormData(form).entries());
    console.log('form validation succeeded')

    // clear faild elements
    faildElements=[];
   setSubmitState({...submitState,validated:true, formData:new FormData(form), errors:null});

    // clean validaty classes
    for (let ee of form.elements) {
      ee.classList.remove('is-valid');
      ee.classList.remove('is-invalid');
    }
    //setFormState({ ...formState, data: formData, validated: true, errors: null });
    //setFormState({ ...formState, validated: true, errors: null });
    //form.reset();

}
  return {
    handelSubmit,
    submitState,
    setSubmitState,  
}
}

export function useAppFormChanges(props:{modelForm: IModelForm, isInputGroup?:boolean}):{childeren:JSX.Element[],formState:Record<string,any>,setFormState:(state:Record<string,any>)=> void }{
  const [formState, setFormState] = useState(props.modelForm.initialState);
   

   let childeren =  props.modelForm?.elements && Object.entries(props.modelForm.elements).map(([_key, elmProps], i: number) => {
    function setChanges(e: any): void {
      let elm = e.target;
      let isRadioCheckBox = "checkbox radio".indexOf(elm.type) !== -1;
      let _name = elm.name;
      let _value = (isRadioCheckBox ? elm.checked : elm.value);

      setFormState({...formState, [_name]: _value });

      if (elm.type !== 'html') {
        validateChanges(e);
      }

     // formData[_name] = data[_name];
      console.log(_name + ' : ' + _value)

    }
      let tagname = elmProps.tag;

      let label = {
        title: elmProps['title'] || _key,
        htmlFor: _key
      }
      let options = undefined;
      let elm: any;
      let isCheckRadio = elmProps.type ? checkboxRadio(elmProps.type) : false
      //set onChange
      elmProps.onChange = setChanges;


      if (tagname === 'select') {
        options = elmProps.options.map((e: any, i: number) => <option key={i} value={e.value}>{e.title}</option>)

        elm = createElement(tagname, { ...elmProps }, options);
      } else if (tagname === 'textarea') {

        elm = !elmProps.html && createElement(tagname, { ...elmProps, value: formState[_key], id: _key });

        if (elmProps.html) {

          let fake_event = (value: string) => {

            return {
              target: {
                type: 'html', name: _key,
                value
              },
              preventDefault: () => true
            }
          }

          return <Editor key={i} value={formState[_key]} onChange={(e: any) => setChanges(fake_event(e.target.value))} />

        }
      } else {

        if (isCheckRadio) {
          elmProps['checked'] = formState[_key]
        } else {
          elmProps.value = formState[_key];

        }

        elm = createElement(tagname, { ...elmProps });
      }

      return <FormVarient key={i} isInputGroup={props.isInputGroup} isRadioCheckBox={isCheckRadio} icon={elmProps.icon} children={elm} label={label} />
   
    });

    return{childeren,formState, setFormState}
  }

function reg(type: string, _val: any) {
  switch (type) {
    case "postcode":
      return /^((([A-PR-UWYZ][0-9])|([A-PR-UWYZ][0-9][0-9])|([A-PR-UWYZ][A-HK-Y][0-9])|([A-PR-UWYZ][A-HK-Y][0-9][0-9])|([A-PR-UWYZ][0-9][A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRVWXY]))\s?([0-9][ABD-HJLNP-UW-Z]{2})|(GIR)\s?(0AA))$/.test(_val.toUpperCase()) ? "" : "enter a valid UK PostCode";
    case "mobile":
      let number = _val;
      number = number.replace(/\(|\)|\s+|-/g, "");
      //var test = number.length > 9 && number.match(reg.mobile);
      return number.length > 9 && number.match(/^(?:(?:(?:00\s?|\+)44\s?|0)7(?:[1345789]\d{2}|624)\s?\d{3}\s?\d{3})$/) ? "" : "enter valid UK mobile number";
    case "tel":
      return /^(?:(?:(?:00\s?|\+)44\s?|0)(?:1\d{8,9}|[23]\d{9}|7(?:[1345789]\d{8}|624\d{6})))$/.test(_val) ? "" : "enter valid UK number";
    case "text":
      // /^[a-zA-Z0-9]+$/.test(_val) ? "" : "text and numbers only";
      return /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(_val) ? "" : "text numbers and space only";
    case "textarea":
      return !(/[^A-Za-z0-9 .'?!,@$#-_]/).test(_val) ? "" : "text, numbers & those chars only !,@$#-_ ";
    case "date":
      return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(_val) ? "" : "this format dd/mm/yyyy";
    case "email":
      return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(_val) ? "" : "valid email eg: aa@aa.com";
    case "password":
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,10}/.test(_val) ? "" : "8 to 10 chars 1 uppercase lowercase number and special char";
    default: return "";
  }
}

function customChecks(elm: any) {
  let tagname = elm.tagName.toLowerCase();
  if (tagname === 'input') {
    elm.setCustomValidity(reg(elm.getAttribute('type')!.toLowerCase(), elm.value));
  }
  else if (tagname === 'textarea') {
    elm.setCustomValidity(reg(tagname, elm.value));
  }
  else if (tagname === 'file') {
    elm.setCustomValidity(checkfile(elm));
  }

}

function checkedUp(elm: HTMLInputElement, extra = false) {
  //extra && customChecks(elm);

  if (!elm.validity.valid) {
    let nxSibling = elm.nextElementSibling;
    if (nxSibling && nxSibling.className.indexOf("invalid-feedback") > -1) {
      nxSibling!.innerHTML = elm.validationMessage;
    } else {
      elm.insertAdjacentHTML("afterend", "<div class='invalid-feedback'>" + elm.validationMessage + "</div>")
    }

    elm.classList.remove('is-valid');
    elm.classList.add('is-invalid');
    return false;
  } else {

    elm.classList.remove('is-invalid');
    elm.classList.add('is-valid');
    return true;
  }
}

function checkfile(elm: HTMLInputElement, filesNo = 2) {
  let files = elm.files, filength = files!.length, _i = 0, _file, fileType;

  if (!files || filength === 0) {
    return `doesn't contain any files.`;
  }

  if (filength > filesNo) return `( ${filength} ) files selcted ? only ${filesNo} allowed`;

  for (_i; _i < filength; _i++) {
    _file = elm.files![_i];
    fileType = _file.type;

    if (_file.name.length > 50) return `file : ${_file.name} , is too long`;

    else if ("image/jpeg image/png image/jpg".indexOf(fileType) === -1) return fileType + ":not accepted only jpeg png jpg images";

    else if (_file.size / 1024 / 1024 > 2) return _file.name + ": size is exceeds 2 MB";

  }

  return "";
}

function checkFormElements(form: HTMLFormElement, extra = false, callback?: Function) {
  for (let item of form.elements) {
    if ("input select, textarea".indexOf(item.tagName.toLowerCase()) !== -1)
      callback ? callback(checkedUp(item as HTMLInputElement, extra), item) : checkedUp(item as HTMLInputElement, extra)
  }
}

function validateChanges(e: any) {
  e.preventDefault();
  e.stopPropagation();
  return checkedUp(e.target);
}


export { checkedUp, reg, checkfile, validateChanges, checkFormElements }