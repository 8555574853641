

export default function Clouds(props?:{center?:boolean}) {
    return (
        <ul className={props?.center === true ? 'list-inline text-center' :'list-inline' }>
            <li className='list-inline-item'> <a target="_blank" className='nav-link' title='Credly profile' href="https://www.credly.com/users/tuban" rel="noreferrer">Credly <i className="bi bi-award-fill text-primary h4"></i></a></li>
            <li className='list-inline-item'> <a  target="_blank" className='nav-link' title='Microsoft Profile' href="https://docs.microsoft.com/en-us/users/tuban" rel="noreferrer"> Microsoft Azure <i className="bi bi-microsoft text-primary"></i></a></li>
            <li className='list-inline-item'> <a target="_blank" className='nav-link' title='github profile' href="https://github.com/Khalillof" rel="noreferrer">GitHub  <i className="bi bi-github text-secondary"></i></a></li>
            <li className='list-inline-item'> <a target="_blank" className='nav-link' title='linkedIn profile' href="https://www.linkedin.com/in/tuban" rel="noreferrer">LinkedIn <i className="bi bi-linkedin text-primary"></i></a></li>
            <li className='list-inline-item'> <a  target="_blank" className='nav-link' title='GCP profile' href="https://g.dev/tuban" rel="noreferrer">Google Cloud <i className="bi bi-google text-primary"></i></a></li>
        </ul>
    )
}