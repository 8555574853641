import * as react from 'react';

interface MaroonCardProps {
    title?:string
    size?:string // xl | lg | md | ''
    textSize?:string // xl | lg | md | sm
    classNames?:string
    ribbon?:string
    card?:string,
    id?:string
}
export default function MaroonCard(props:react.PropsWithChildren<MaroonCardProps>){

   return(
    <div id={props.id} className={`card card-body card-${props.ribbon ?? 'maroon'} card-outline my-1 py-2 shadow-none ${props.classNames!}`}>
        {props.title ? 
    <div className={`ribbon-wrapper ribbon-${props.size ?? 'lg'}`}>
                <div className={`ribbon bg-${props.ribbon ?? 'maroon'} text-${props.textSize ?? 'md'}`}>
                {props.title}
                </div>
            </div>
        : ""
}
      {props.children}

    </div>
   )
}