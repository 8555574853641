import {useLoaderData} from 'react-router-dom';
import { IModelForm} from '../../types/utils';

import { AppForms} from '../../utils/forms';
import { Loading } from '../../components';
import { useModelLayoutContext } from './default_model_layout';

export function CreateModel() {

  const modelForm = useLoaderData() as IModelForm; 
  const {viewData} = useModelLayoutContext()!
  const { name} = viewData;

  let Extra = ()=>{
    return<div className=" mx-auto text-center mb-3">
    <p className='lead'> Use this form to Create {name}</p>
  </div>
  }
  //const {AppForm,farmState, setFarmState}= useFarmBuilder({modelForm,sendUrl:routeName,send:true, Extra:<Extra/>});

  return viewData ?  (

    <div className=" container-fluid p-0 justify-content-md-center fadein">
      <div className='row'>
        <div className="col-md-10 col-sm-12 mx-auto ">
            <h4 className='text-center'>{'Create ' + name}</h4>
            <hr className='bg-warning' />
             { 
             // <AppForm/>
          }
          {
            // key is very important it makes form rerender on props change
 modelForm ? <AppForms key={modelForm.name} modelForm={modelForm} method='post' Extra={<Extra/>} /> : <Loading/>

 }

        </div>
      </div>
    </div>
  ) : <Loading/>;
}
