
import { Link } from 'react-router-dom';


export const GoHome = () => {
  return (
            <div className="text-center mx-auto">
                 <hr/>
                  <Link to="/"  className="btn btn-outline-primary" >
                <i className="bi bi-chevron-double-right mt-2"></i>
                  Go To home Page
                </Link>
          </div>
  );
};
