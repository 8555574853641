
import { Outlet, useOutletContext } from 'react-router-dom';
import { ISiteContextValues, IViewData} from '../../types/utils';
import { MaroonCard} from '../../components';
import { Loading, RenderCategories, FeaturedPosts} from '../../components/helpers';
import { useSiteContext } from '../../utils/context';

export interface IModelLayoutContext {
  cnx: ISiteContextValues
  viewData: IViewData
}

export const useModelLayoutContext = () => useOutletContext<IModelLayoutContext>();

export function DefaultModelLayout(props: { viewData: IViewData}) {
  const viewData = props.viewData;
  const cnx = useSiteContext()!;

  //const modelViewData: IModelViewData = useMemo( ()=> new ModelViewtData(viewData),[viewData]);
  
  const sidebar = viewData.name  === 'post';
  

  return <MaroonCard  title={viewData.name}>
    <div className="container-fluid p-0 ">
      {sidebar && <FeaturedPosts />}

      <div className="row py-3">
        <div className={sidebar ? "col-md-9" : "col-md-12"}>

        <Outlet context={{ cnx, viewData} as IModelLayoutContext} />

        </div>
        {sidebar &&
          <div className="col-md-3">
            <div className="position-sticky" style={{ top: 10 }}>
              <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">About</h4>
                <p className="mb-0">Customize this section to tell your visitors a little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
              </div>
              <RenderCategories />
              <div className="p-4">
                <h4 className="fst-italic">Archives</h4>
                <ol className="list-unstyled mb-0">
                  <li><a href="/">March 2021</a></li>
                  <li><a href="/">February 2021</a></li>
                  <li><a href="/">January 2021</a></li>
                  <li><a href="/">December 2020</a></li>
                  <li><a href="/">November 2020</a></li>
                  <li><a href="/">October 2020</a></li>
                  <li><a href="/">September 2020</a></li>
                </ol>
              </div>

              <div className="p-4">
                <h4 className="fst-italic">Elsewhere</h4>
                <ol className="list-unstyled">
                  <li><a href="/">GitHub</a></li>
                  <li><a href="/">Twitter</a></li>
                  <li><a href="/">Facebook</a></li>
                </ol>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </MaroonCard>
};