
import {RouterProvider} from 'react-router-dom';
import useAppRoues from './utils/routes';
import { Loading } from './components';
import { Suspense} from 'react';
import { useSiteContext } from './utils/context';

export default function App() {
   const browserRoutes = useAppRoues();
   const cnx = useSiteContext();
   

return <Suspense fallback={<Loading/> } >

          { (!cnx?.siteState.isOnline) ? <div className='container border pt-5 '><h1 className='text-danger text-center'>Oops You Are Offline ...! <br/> <h6>when you are connected please refresh the page</h6></h1></div>  :
        cnx?.siteState.serverDown  ? <div className='container border pt-5 '><h1 className='text-danger text-center'>Oops Server down try again later please ...!</h1></div> :
        (!cnx?.siteState.serverDown && cnx.viewsData?.length) && browserRoutes ? <RouterProvider fallbackElement={<Loading/>}  router={browserRoutes} /> :
        <div className='container border pt-5 '><Loading/> <h1 className='text-primary text-center'>App is Loading ...!</h1></div> 
        }
        
</Suspense>

}



