import { useShopState} from '../../utils/context'
import {IProduct} from './reducers/cartReducer'
 //import '../components/styles.css'
import { ISiteContextValues } from '../../types/utils';


export const Products =(props:{cnx:ISiteContextValues})=>{
   
const {state ,AddToCart} = useShopState(props.cnx);

    
        let itemList = state.items.map((item:IProduct, i)=>{
            return(
                <div key={item.id} className='col-md-3 '>
                <div className="card" key={item.id}>
                <img className=' d-block mx-auto' src={item.img} style={{ width:"170px"}} alt={item.title} />


                        <div className="card-body">
                            <h5 className="card-title ">{item.title} </h5>
                            
                            <p>{item.desc}</p>
                            
                            <div className='row'>
                                <div className='col'>
                                <p>Price: <span className="badge text-bg-primary"><i className="bi bi-currency-pound"></i> {item.price}</span></p>
                        
                                </div>
                                <div className='col'>
                            <button type='button'  className="btn btn-outline-danger btn-sm float-end" onClick={()=>{AddToCart(item.id)}}>add to <i className="bi bi-cart-plus-fill"></i></button>
                                </ div>
                            </div>
                        </div>
                 </div>
                 </div>

            )
        })

        return(
            <>
                <h3 className="text-center">Our items</h3>
                <div className="row">
                    {itemList}
                </div>
            </>
        )
    
}


