import { useLocation, useRouteError } from "react-router-dom";
import { GoHome } from "./gohome";

export function ErrorPage() {
  const error:any = useRouteError();
  const {state} = useLocation();
  

  return (
    <div className="text-center mx-auto" id="error-page">
      <h1 className="text-danger text-center">Oops!</h1>
      <p className="lead">Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{(state?.error && ( state.error?.statusText || state.error?.message )) ??   (error && ( error.statusText || error.message))}</i>
      </p>
      <GoHome/>
    </div>
  );
}