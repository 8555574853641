import { useMemo, useState } from 'react';
import { IAjax,IRequestResult} from '../types/utils'

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.tuban.me/api/' : 'http://localhost:8000/api/';


function setQueries(path: string, querys?: Record<string, any> | string): URL { 
  
  let url = new URL(path!,baseUrl);
  if (querys){

    if(typeof querys === 'object'){
    for (let [key, value]  of Object.entries(querys)) {
      url.searchParams.set(key, value);
    }
  }else if(typeof querys === 'string'){
    url.search = querys as string
  }else{
    throw new Error('expected onject litral or string for url search query')
  }
  }
  return url;
}

export class Ajax implements IAjax {

  constructor(access_token?:string) {;
    if(access_token)
      this.access_token = access_token;

  }
  baseUrl: string = baseUrl;
  access_token?:string

  async init(prams: { method: string, path: string, querys?: Record<string, any> | string, data?: any}) {
    let { method, path,querys, data} = prams;

    let url: URL = setQueries(path, querys!);

    const getHeaders = () => {
      const headers = new Headers();
      
      headers.append('Content-Type', 'application/json')
      headers.append('Access-Control-Allow-Origin', this.baseUrl!)

      if (this.access_token) {
        //console.log('accessToken: '+this.auth.state.data?.tokens?.accessToken)
        headers.append('Authorization', `Bearer ${this.access_token}`);
      }
      return headers;
    };
  

    const _settings:any ={
      method: method,
      mode: 'cors',
      //credentials: 'same-origin', // include, *same-origin, omit // include to include cookies
      headers: getHeaders(),
      referrerPolicy: 'no-referrer',
      body:data && JSON.stringify(data),
      cache: 'default'
    };

    return fetch( url, _settings)
    .then(async (responce) => await responce.json())
    .catch(error => {
     
      console.error('error fetching data : ',error)
  
      throw error;
    })


  }

  async request(props: { method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', path: string, querys?: Record<string, any>| string, data?: any}){
    return await this.init(props);
  
  }


  async  get(path: string, querys?: Record<string, any>| string): Promise<IRequestResult>{
    return await  this.request({method:'GET',path,querys});
  }

  async  post(path: string,data?: any): Promise<IRequestResult>{
    return await  this.request!({method:'POST',path,data});
  }
  async  put(path: string, data?: any): Promise<IRequestResult>{
    return await  this.request!({method:'PUT',path,data});
  }

  async patch(path: string, data?: any): Promise<IRequestResult>{
    return await  this.request!({method:'PATCH',path,data});
  }
  async deleteReq(path: string, querys?: Record<string, any>| string): Promise<IRequestResult>{
    return await  this.request!({method:'DELETE',path,querys});
  }

}

// useAjax
export function useAjax(props:{access_token?:string}):IAjax {
 
  async function init(prams: { method: string, path: string, querys?: Record<string, any>| string, data?: any}) {
    let { method, path,querys, data} = prams;
    
    let url: URL = setQueries(path!?? baseUrl, querys!);
    
    const body = data && JSON.stringify(data); // body data type must match "Content-Type" header

    return fetch( url, {
      method: method,
      mode: 'cors',

      //credentials: 'same-origin', // include, *same-origin, omit
      headers: (() => {
        const headers = new Headers();
        
        headers.append('Content-Type', 'application/json')
        headers.append('Access-Control-Allow-Origin', baseUrl!)
       
  
        if (props.access_token) {
          //console.log('accessToken: '+this.auth.state.data?.tokens?.accessToken)
          headers.append('Authorization', `Bearer ${props.access_token}`);
        }
        return headers;
      })(),
      referrerPolicy: 'no-referrer',
      body,
      cache: 'default'
    }).then((responce: any) => responce.json()).catch(error => {
      console.error('error fetching data : ', error.message)
      return { success: false, error: 'server error : ' + error.message }
    })


  }

  async function request(props: { method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', path: string, querys?: Record<string, any> | string, data?: any}){
    return await init({ ...props});
  
  }
  
  async function  get(path: string, querys?: Record<string, any> | string): Promise<IRequestResult>{
    return await  request!({method:'GET',path,querys});
  }

  async function  post(path: string,data?: any): Promise<IRequestResult>{
    return await  request!({method:'POST',path,data});
  }
  async function  put(path: string, data?: any): Promise<IRequestResult>{
    return await  request!({method:'PUT',path,data});
  }

  async function patch(path: string, data?: any): Promise<IRequestResult>{
    return await  request!({method:'PATCH',path,data});
  }
  async function deleteReq(path: string, querys?: Record<string, any> | string): Promise<IRequestResult>{
    return await  request!({method:'DELETE',path,querys});
  }

  return{
      init,
      request,
      get,
      post,
      put,
      patch,
      deleteReq
  }
}

export interface IFetcher<DataType> extends IRequestResult 
{ state: "idle" | "loading" | "loaded" | "error", data: DataType | null}

export function useAppFetcher<DataType>(props: { method: 'GET' | 'POST' | 'PUT' | 'DELETE'| 'PATCH', path: string, querys?: Record<string, any>| string, data?: any, access_token?:string}) {

  const [state, setState] = useState<IFetcher<DataType>>({ state: "idle", data: null, success:false});

 return useMemo(() => {
    if (state.state === "idle") {
      const ajax = new Ajax(props.access_token);
      setState({ ...state, state: 'loading' })
     ajax.request!(props)
    .then((result:IRequestResult)=> {
      setState({ ...state, state: "loaded", ...result })
    })
    .catch(error => {
      setState({ data:null, state: "error", error, success: false})
      console.error(error)

    })
  }
  return state;
  }, [props, state])
  
}