
import React from "react";
import { Link } from 'react-router-dom';
//import Login  from './Login';


export const ForgotPassword = () => {
  return (
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <div className="container">
          <div  className="row justify-content-center">
            <p className="text-center">
              <Link to="/login" className="card-link text-gray-700">
              <i className="bi bi-chevron-double-right"></i> Back to sign in
            </Link>
            </p>
            <div className="col-xs-12 d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                <form>
                  <div className="mb-4">
                    <span className="input-group-text" >Your Email</span>
                    <div className="input-group" id="email">
                      <input  className="form-control" name="email" required autoFocus type="email" placeholder="john@company.com" />
                    </div>
                  </div>
                  <button  type="submit" className="btn btn-block btn-primary">
                    Recover password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};
