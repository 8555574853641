import React, {useState, useEffect} from 'react';
import scrolTop from "../assets/icons/icons8-upward-arrow.gif";


export default function ScrollTop(){
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
      
     // window.addEventListener('scroll', toggleVisible);

      useEffect(()=>{
        window.addEventListener('scroll', toggleVisible);

        return () => {
          window.removeEventListener("scroll", toggleVisible);
        };
      },[visible])
     
    return (
    <span className={visible ? 'scroll-up d-block' : 'scroll-up d-none'} id="scroll-up" onClick={scrollToTop}>
    <img src={scrolTop} className="socicon up-arrow rounded-circle" alt="" />
    </span>
    
)
    }
