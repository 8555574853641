import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/adminLiteCustom.css';
import './css/index.css';
import {SiteContextProvider} from './utils/context';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider} from "react-oidc-context";
import {oidcConfig} from './utils/AuthService';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
    <SiteContextProvider >
<App />
    </SiteContextProvider>
   </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
