import { useMemo, useState, useEffect, useRef, useCallback, PropsWithChildren } from 'react';
import { Navigate, useNavigate, useLocation, Link, NavigateFunction} from 'react-router-dom';
import history from '../utils/history';
import MaroonCard from '../components/MaroonCard';
import { IAjax, IAppCaches, ISiteContextValues, IViewData } from '../types/utils'
import { IComment, IRenderComments, ISearch, ICategory, IPost } from '../types/models';
import moment from 'moment';
import {useAppFetcher } from '../utils/ajax';
import { renderTemplate} from '../pages/default/use_renders';
import { useSiteContext } from '../utils/context';

export const isIn =(src: any[] | string, item: any)=> src.indexOf(item) !== -1;

export function FeaturedPosts() {

  const state =  useAppFetcher<IPost[]>({method:'GET',path:'posts',querys:{ isfeatured: true, limit:2, page: 1 }});

  const RenderFeatured = (model: IPost) => (
    <div className="col-md-6 col-sm-6" >
      <MaroonCard textSize="sm" title='Featured'>

        <h5 className="text-truncate">{model.title}</h5>
        <h6 className="mb-0 fw-bold">Featured post</h6>
        <div className="mb-1 text-muted">
          {moment(model.createdAt).format('MMMM Do, YYYY | h:mm a')}
          | By:  <Link to={"/portfolio"}>
            {model.author?.firstname! + ' ' + model.author?.lastname!}
          </Link>
        </div>
        <p className="card-text text-truncate">{model.descriptions}.</p>
        <ContinueReading to={'/posts/' + model._id} state={{ model }} />

      </MaroonCard>
    </div>
  )

  return useMemo(()=>{ 
   return state.data?.length ?
      (<div className="row">
        {state.data.map((p: IPost) => <RenderFeatured {...p} key={p._id} />)}
      </div>) : <Loading />
  },[state.data])
}
export const useCategories = (): ICategory[] | null => {
  const { data } = useAppFetcher<ICategory[]>({ method: 'GET', path: 'categories' });
  return useMemo(()=>data,[data]);
}

export const RenderCategories = () => {
  const _categories = useCategories();
 
  const Category = (cat: ICategory) => <Link to={'/posts/list?page=1&category=' + cat._id} state={{ category: cat }} className="btn btn-outline-dark btn-sm m-1">{cat.name}</Link>

  return (
    _categories && _categories.length ?
      <div className="py-3 d-grid gap-2 d-md-block">
        <h4> Categores : <i className="bi bi-tags"></i></h4>
        {_categories.map((c: ICategory) => <Category  {...c} key={c._id} />)}
      </div>
      : <Loading />
  )
}

export function DynamicLinks(props:PropsWithChildren<{routeName:string,appCaches: IAppCaches,cacheKey:string, navigate: NavigateFunction,dataList:boolean}>){

 const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let _routes:string[] = props.appCaches.get('routesPath');
    let anchorElements = ref.current?.querySelectorAll('a');
// Now you can work with the NodeList of <a> elements
anchorElements?.forEach(anchor => {
 
  let { pathname, search } = new URL(anchor.href);
  let [empty, Rname, _id] = pathname.split('/');
  let url = pathname +search;
  //console.log(isIn(_routes,Rname));
  if(isIn(_routes,Rname)){

    anchor.addEventListener('click', (e) => {
      e.preventDefault();
    
// push to history
history.push(props.cacheKey);

      if(Rname === props.routeName && props.dataList && _id){
        //console.log('set on click to :',props.cacheKey )
        let storedState = props.appCaches.get(props.cacheKey);

        if (storedState && storedState.dataList) {
          let model = storedState.dataList.find((c: any) => c._id === _id) ?? null;

           if(model){
             props.navigate(url, { state: { model } })
           }else{
            props.navigate(url) 
           }
        }
      }else if (Rname === props.routeName && !props.dataList){
        props.navigate(url);
      }else{
        props.navigate(url);
    }

    })

  }
 
});
//=================================

    return () => {

      anchorElements?.forEach((a)=>{
        a.removeEventListener('click', (e) => {
          e.preventDefault();
          // e.stopPropagation()
          console.log('removed on click fired:', e.target)
          //navigate(a.href);
  
        })
      });
    }
  }, [props])

  return<div className='DivLinks' ref={ref}>{props.children}</div>

}


export function ToHTML(props: { __html: string | TrustedHTML, link?: { to: string, state: any } }) {
  const { __html, link } = props;
  return link ? <><div dangerouslySetInnerHTML={{ __html }} /> <ContinueReading {...link} /></> : <div dangerouslySetInnerHTML={{ __html }} />
}

export function Paginator(props: {viewData:IViewData, ajax: IAjax, currentPage: number, pagesPerPage: number }) {
  const navigate = useNavigate();
  const {viewData, currentPage, pagesPerPage, ajax } = props;

  const [totalPages, setTotalPages] = useState(1);

  useEffect(()=> {
  
    ajax.get(viewData.routeName+'/count').then((value)=> {
   if(value.data)
    setTotalPages(value.data);
    })
 
  },[ajax, viewData.routeName])

  const getPageUrl = (page = currentPage) => `/${viewData.routeName}?page=${page}`;

  let lastPage = Math.ceil(totalPages / pagesPerPage);

  const page_slices = useCallback(() => {
    let slices: number[] = [];

    for (let i = 1; i < lastPage + 1; i++) {
      slices.push(i)
    }
    return slices
  }, [lastPage])


    const setPageChanges = (e: any, page: number) => {
      e.preventDefault();
  
      if (currentPage === page || page < 1 || page > lastPage) {
        return;
      }
      navigate(getPageUrl(page), { replace: true });
  
    };

   const PageItem =(props: { page: any, content: any, label?: string, disabled?: boolean })=> <li className={currentPage === props.page ? "page-item active" : "page-item"}>
      <Link to={getPageUrl(props.page)} onClick={(e) => setPageChanges(e, props.page)} className={props.disabled ? "disabled page-link" : "page-link"} aria-label={props.label}>{props.content}</Link>
    </li>


  const pageEqual1 = currentPage === 1;
  const lastPageEqualCurrentPage = lastPage === currentPage;
  return (
    <nav aria-label="Page navigation example ">
      <ul className="pagination justify-content-center">
        <PageItem page={1} disabled={pageEqual1} content="First" />
        <PageItem page={currentPage - 1} disabled={pageEqual1} label="Previous" content={<i className="bi bi-chevron-double-left"></i>} />

        {page_slices().map((page: any) => (<PageItem key={page} page={page} content={String(page)} />))}

        <PageItem page={currentPage + 1} disabled={lastPageEqualCurrentPage} label="Next" content={<i className="bi bi-chevron-double-right"></i>} />

        <PageItem page={lastPage} disabled={lastPageEqualCurrentPage} content="Last" />

      </ul>
    </nav>
  );
}

export const IsHtml = (props: { obj: any }) => {
  const { obj } = props;
  if (typeof obj === 'string') {
    if (obj.indexOf('<br/>') !== -1) {
      return <div className='lead' dangerouslySetInnerHTML={{ __html: obj }} />
    } else {
      return <p className='lead' >{obj}</p>
    }
  } else {
    return <p className='lead' >{String(obj)}</p>
  }
}

// used for search functionality
export function DynamicSearch(props: { viewData: IViewData }) {
const cnx = useSiteContext();
  const { routeName, searchKey } = props.viewData;
  const clear = () => setState({ ...state, searchQuery: null, searchResult: null, emptySearchResult: null, errorResult: null });

  const [state, setState] = useState<ISearch>({
    searchKey,
    searchQuery: null,
    searchResult: null,
    errorResult: null,
    emptySearchResult: null,
    routeName
  });


  const handleSearch = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const searchQuery = event.target.search.value
    setState({ ...state, searchQuery: searchQuery, searchResult: null, errorResult: null })
    //console.log('search value before send : ', search_query)

    cnx?.ajax.get(routeName + '/search', { [searchKey]: searchQuery })
      .then(({ error, data }) => {

        //console.log('search result data : ', data)
        // if return result not null and contain items in array
        if (data && data.length) {
          let _data: any = renderTemplate(props.viewData.listTemplate, data);
          setState({ ...state, searchResult: _data, emptySearchResult: null, searchKey, errorResult: null, searchQuery })
        }
        // if retun is error string
        else if (!data && error) {
          setState({ ...state, searchResult: null, emptySearchResult: null, searchKey, errorResult: error, searchQuery })
        }
        // if retun is empty array
        else if (data && Array.isArray(data)) {
          setState({ ...state, searchResult: null, emptySearchResult: searchQuery, searchKey, errorResult: null, searchQuery })
        }
      });


    //reset input
    event.target.reset()
  },[cnx?.ajax, props, state]);


  const sortSearchResult = (content: any, error: boolean = false) => (<div className='my-3 text-center'><h6> {error ? "ERROR" : "Nothing found for text :"}  {<span className='text-danger'>{content}</span>} </h6><br /><button type='button' className="btn btn-primary" onClick={clear}> Clear </button></div>)

  return (<>
    <form className="hstack gap-1  p-3 w-100 " role="search" id='searchpost' onSubmit={handleSearch}>
      <input className="form-control me-2" name='search' type="search" minLength={3} maxLength={100} required={true} placeholder="Search" aria-label="Search" />
      <button type="submit" className="btn btn-secondary">Submit</button>
      <div className="vr"> </div>
      <button type="reset" onClick={clear} className="btn btn-outline-danger me-5">Reset</button>
    </form>
    {state.searchResult ? <MaroonCard size='sm' textSize='sm' title={'search'} classNames='fadein h-50'> {state.searchResult && state.searchResult.map!((html: any, i) => <ToHTML key={i} __html={html} />)} </MaroonCard> : <></>}
    {state.emptySearchResult ? sortSearchResult(state.emptySearchResult) : <></>}
    {state.errorResult ? sortSearchResult(state.errorResult, true) : <></>}
  </>
  )
}

export function RenderComments(props: { modelid: string, cnx: ISiteContextValues }) {
  const {  modelid, cnx } = props;
  const { ajax } = cnx;

  const [state, setData] = useState<IRenderComments>({
    modelid,
    status: 'ideal',
    commentShown: false,
    commentsList: null,
  })

  const {pathname, search} = useLocation();

 // reset state when url or modelid changes
  useEffect(() => {
      
    // reset state when url or modelid changes
      
      setData({
        status:'ideal',
        modelid,
        commentShown: false,
        commentsList: null,
      })

    console.log('comment states rested')
  }, [modelid])


  const RenderComment = (props: { comment: IComment }) => {

    return (
      <div key={props.comment._id} className="card ">
        <div className='card-body'>
          <p className='card-text'>{props.comment.body} </p>
          <p className='card-text'> By: {(props.comment.user.firstname ?? 'You') + ' ' + (props.comment.user.lastname ?? 'Commented')} </p>
          <span>{'Date: ' + moment(props.comment.createdAt).format('MMMM Do, YYYY | h:mm a')}</span>
        </div>
      </div>
    );
  }
  const handleCommentSubmitStates = useCallback((submitted_comment: any) => {
    if (state.commentsList) {
      setData({ ...state, commentsList: [submitted_comment, ...state.commentsList] })
    } else {
      setData({ ...state, commentsList: [submitted_comment] })
    }
  },[state]);
  
  const handleSubmit = useCallback((event: any) => {
    event.preventDefault()

    const _data = {
      body: event.target.comment.value,
      modelid,
      user:cnx.auth.user?.profile.sub,
    }

    ajax.post('comments/create', _data).then(({data, error}) => {

      if (data) {
        handleCommentSubmitStates(data)
        event.target.reset()
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      }
      if (error) console.log(error)
    })


  },[ajax, handleCommentSubmitStates, modelid,cnx.auth.user])




  const loadComments = useCallback(async () =>{
   
    if (state.status === 'ideal') {
      setData({...state,status:'loading'});

      ajax.get('comments', { modelid: state.modelid, sort: -1 })
      .then(({data, error})=> {
        data && setData({ ...state, commentShown: true, status:'loaded',commentsList: data })
       
        if(error){
          setData({...state,status:'error', commentShown:true})
           console.log(error)
        }
      })
      .catch(err => console.log(err));
    }
  },[ajax, state])

 
  return (<div className='w-100'>
    <a onClick={(e)=> { e.preventDefault();  !state.commentShown &&  loadComments()}} className=" btn  btn-outline-primary my-3" data-bs-toggle="collapse" href="#comments" role="button" aria-expanded="false" aria-controls="collapseSkills">
    
   { state.status === 'loading' ?
   (<><span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
   <span  role="status"> Loading... </span></>)
   : <h6 >Comments</h6>
   }

    </a>

    <div className='w-100 collapse fadein' id='comments'>
      {Array.isArray(state.commentsList) && state.commentsList.map((comment: IComment) => <RenderComment key={comment._id} comment={comment} />)}

      <div>
        {cnx.auth.user
          ?
          (<form onSubmit={handleSubmit} style={{ maxWidth: 600 }}>
            <div className='form-group b=mb-3'>
              <textarea
                className='form-control'
                aria-label='Comment'
                id="comment"
                name="comment"
                //label="Comment"
                rows={2}
                placeholder='add comment'
              />
              <br />
            </div>
            <div>

            </div>
            <button className='btn btn-primary' type="submit">
              Submit
            </button>


          </form>) : (<Link to={'/login?returnUrl='+pathname+search}>
            <button type='button' className='btn btn-outline-primary'>
              Sigin to Comment
            </button>
          </Link>)
        }
      </div>
      <div>

      </div>
    </div>
  </div>)

}

export const goTo = (url: string, state?: any) => typeof url === 'string' ? <Navigate to={url} state={state!} /> : new Error('expected string url');
export const GoBack = () => <button type='button' className='btn btn-lg btn-primary my-3' onClick={() => history.back()}> Go Back </ button>

export const ContinueReading = (props: { to: string, state?: any }) => (<Link {...props}> Continue reading... !</Link>)

export const Loading = () => (<div className="d-flex justify-content-center mb-3">
  <div className="spinner-border text-primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
</div>)


export function useGetSetKeys(name: string, url?: string): string | null {
  const {data} = useAppFetcher<string>({ method: 'GET', path: url ?? 'accounts/secure', querys: { api: name } });
  return data;
}

export function UserLikes(props: { modelid: string, cnx: ISiteContextValues }) {
  const { modelid, cnx } = props;
  const { ajax } = cnx;
  const {pathname,search}  = useLocation();
  const navigate = useNavigate();

  const [state, setData] = useState<{
    fetched: boolean,
    likeId: string | null
    like: boolean
    likes: number
    modelid: string | undefined
  }>({
    fetched: false,
    likeId: null,
    like: false,
    likes: 0,
    modelid
  })

useEffect(()=>{
  if(state.modelid !== modelid){
   setData({modelid,fetched:false,like:false,likes:0, likeId:null})
  } 
  //console.log('like reset');
},[state.modelid,modelid]);

  useEffect(() => {

    if (!state.fetched) {
 
      ajax.get('likes', { modelid, like:true }).then(async ({data, success,error,total}) => {

        if (success && data ) {
          
          let likes = data;
        
          if(cnx.auth.user && data >=1){
          let userLike =  await ajax.get('likes',{modelid, user:cnx.auth.user?.profile.sub});

           if(userLike.data && userLike.data.length){
            let {like, _id} = userLike.data[0];
             setData({fetched: true,likes, likeId:_id, like,modelid})
            }

          }else{
            setData({like:false,modelid,likeId:null, fetched: true,likes})
          }
       
        } else {
          setData({ ...state, fetched: true });
        }
      })

    }

  }, [ajax, modelid, cnx.auth.user])



  const handleLikes = () => {

    if (state.likeId && cnx.auth.user) {
      let sendData = { modelid:state.modelid, like: !(state.like) , user:cnx.auth.user?.profile.sub};

      let url = 'likes/' + state.likeId + '/update';
      ajax.put(url, sendData).then(({success, error}) => { 
        if(success){
          let likes = state.likes+ (sendData.like ? 1 : -1);
         setData({...state, ...sendData, likes })
        }
        
         error && console.log(error)
      });
    } else if(cnx.auth.user) {
      let sendData = {modelid:state.modelid, like: true, user:cnx.auth.user?.profile.sub };
      ajax.post('likes/create', sendData).then(({data, error}) => {
        let likes = state.likes+  (data.like ? 1 : -1);
        data && setData({...state, ...data, likeId:data._id, likes })
         error && console.log(error)
      });
    }

  }

  return (

      <button type="button" className="btn btn-outline-primary btn-sm "
        onClick={(event: any) => {
          event.preventDefault() 
          cnx.auth.user ? handleLikes() : navigate('/login?returnUrl='+pathname+search)
}}
      >
        <i className={state.like ? "bi bi-heart-fill " : "bi bi-heart" }></i>
   
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {state.likes!}
          <span className="visually-hidden">unread messages</span>
        </span>
      </button>


  )
}

export const FadeIn = (props: { children: any, className?: string }) => <div className={`w-100 fadein ${props.className}`}> {props.children}</div>


export const LoadingButton = (props: { text?: string, type?: 'button' | 'submit' | 'reset', className?: string, loading: boolean, disabled?: boolean, onSubmit?(e: any): void, onClick?(): void }) => {
  const { text, type, className, loading, disabled, onSubmit, onClick } = props;

  return <button disabled={disabled ? disabled : loading} type={type || 'button'} className={className || 'btn btn-danger my-3 float-end'} onSubmit={onSubmit} onClick={onClick}>
    {props.loading ? (<><span className="spinner-grow spinner-grow-sm text-danger" role="status" aria-hidden="true"></span> Loading...</>) : (text || 'Submit')}
  </button>
}

export const useWindowEvent = (evenType: string, callback: (e: any) => void): void => {
  useEffect(() => {
    console.log(evenType + " subscribe")
    window.addEventListener(evenType, callback)
    return () => {
      console.log(evenType + " unsubscribe")
      window.removeEventListener(evenType, callback)
    }
  }, [evenType, callback])
}

const setOnLoad = (script: HTMLScriptElement | HTMLLinkElement, url: string, callback?: (loadFeedback: { loaded: boolean, error: string | null }) => void) => script.onload = (e: any) => {

  let loaded = e.type === "load";
  let msg = `loading external ${script.tagName} event type :  ${e.type}, loaded:${loaded} url: ${url}`;
  console.log(msg)
  return callback ? callback({ loaded, error: (loaded ? null : msg) }) : { loaded, error: (loaded ? null : msg) };
};

export async function scriptsAsync(src: string[]) {
  return await Promise.all(src.map(async (s) => await loadAppendScript(s, (feeds, doc) => ({ feeds, doc }))))
}
export function useLoadScript(src: string[], run: boolean = false) {
  const [state, setState] = useState<{ loaded: boolean, error: string | null }>({ loaded: false, error: null });
  let docScripts = useRef<HTMLScriptElement[] | HTMLLinkElement[] | null>(null)

  useEffect(() => {
    if (run && docScripts.current === null && !state.loaded && src && src.length) {

      scriptsAsync(src).then((eee) => {
        console.log('scripts result : ', eee)
        let len = src.length - 1;

        if (eee && eee.length && eee[len]) {
          let res: any = eee[len];
          if (res.feeds.loaded)
            docScripts.current = eee.map((d: any) => d.doc);

          setState({ ...state, loaded: true, error: null })
        } else {
          setState({ ...state, loaded: true, error: " error loading some of your documents" })
        }
      })

    } else if (run && docScripts.current !== null && !state.loaded) {
      setState({ ...state, loaded: true });
    }
  }, [run, src, state]);
  //}, [run, src, state]);

  return { state, ready: (docScripts.current !== null || state.loaded) }
};

export async function loadAppendScript(url: string, callback?: (feeds: { loaded: boolean, error: string | null }, doc: HTMLScriptElement | HTMLLinkElement | null) => void) {
  let _type = (url.endsWith('.js') && 'js') || (url.endsWith('.css') && 'css') || null;

  const runScript = (doc: HTMLScriptElement | HTMLLinkElement) => {
    setOnLoad(doc, url, (feeds) => callback && callback(feeds, doc));
    document.head.appendChild(doc);
    // console.log('Script tg =============================================', scriptDoc)

  }

  if (!_type) {
    throw new Error(' loading script require document type either js or css');
  }

  const srcQuery = _type === 'js' ? `script[src="${url}"]` : `link[href="${url}"]`;
  let doc: HTMLScriptElement | HTMLLinkElement | null = document.head.querySelector(srcQuery);

  if (doc) {
    return callback ? callback({ loaded: true, error: null }, doc) : { feeds: { loaded: true, error: null }, doc };
  }
  else if (_type === 'js') {
    if (!doc) {
      doc = document.createElement("script");
      doc.type = "text/javascript";
      doc.src = url;
      doc.crossOrigin = "anonymous"
      doc.referrerPolicy = "no-referrer"
      doc.async = true;
      runScript(doc);
      return;
    }

  } else if (_type === 'css') {
    if (!doc) {
      doc = document.createElement("link");

      //scriptDoc.type = "text/css";
      doc.rel = "stylesheet";
      doc.href = url;
      doc.crossOrigin = "anonymous"
      doc.referrerPolicy = "no-referrer"

      runScript(doc);
      return;
    }
  }
};

export async function runLogedInUserScripts() {
  const adminRquiredScripts = ["https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.6.0/build/highlight.min.js",
    "https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.6.0/build/styles/default.min.css",
    "https://cdn.quilljs.com/1.3.6/quill.min.js",
    "https://cdn.quilljs.com/1.3.6/quill.snow.css"];
  //(feedback: { loaded: boolean, error: string | null }, scriptDoc) => {}

  return await Promise.all(adminRquiredScripts.map(async (scriptUrl: string) => {
    return loadAppendScript(scriptUrl, (feedback: { loaded: boolean, error: string | null }, scriptDoc) => { })
  }))
}